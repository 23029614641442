import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
require('./bootstrap');
require('slick-carousel');

(function($) {
	$(document).ready(function() {

		$.ajaxSetup({
	        headers: {
	            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	        }
	    });

	 //    window.onbeforeunload = function () {
	 //      $.showOverlay($('#loading-overlay'));
		//   window.scrollTo(0, 0);
		// }

		if($('#loading-overlay').length){
			$('#loading-overlay').on('click',function(e){
	        	e.preventDefault();
	        	setTimeout(function() {
	                $.removeOverlay($('#loading-overlay'));
	            }, 600);

	        	return false;
	        });

	        $('body').on('click','.clear-overlay', function(e){
	        	setTimeout(function() {
	                $.removeOverlay($('#loading-overlay'));
	            }, 1200);
	        });
		}

		// if($('#frontpage-videos-list').length){
		// 	setTimeout(function() {
  //               get_frontpage_videos();
  //           }, 800);
			
		// }



			if($('.videos-list-holder').length > 0){
		  	$('.videos-list-holder').slick({
		            dots: false,
		            autoplay: true,
		            autoplaySpeed: 6500,
		            infinite: false,
		            speed: 300,
		            slidesToShow: 3,
		            slidesToScroll: 3,
		            nextArrow: '#videos-list-next',
		            prevArrow: '#videos-list-prev',
		            responsive: [
		              {
		                breakpoint: 991,
		                settings: {
		                  slidesToShow: 3,
		                  slidesToScroll: 3,
		                  infinite: true
		                }
		              },
		              {
		                breakpoint: 767,
		                settings: {
		                  slidesToShow: 1,
		                  slidesToScroll: 1,
		                  infinite: true
		                }
		              }
		            ]
		    });

		    if($('#videos-details').length > 0){
		    	$('#videos-details .videos-details-slider').on('init', function(slick){
		    		var videoIframes = $('#videos-details .videos-details-slider').find('.video-frame');
		    		$.each(videoIframes,function(i,k){
		    			if($(k).data('url')){
		    				var videoLink = $(k).data('url');
		    				if(videoLink){
		    					var iframeHtml = '<iframe src="'+videoLink+'" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true" loading="lazy" class="lazyload"></iframe>';
		    					$(k).html(iframeHtml).promise().done(function(){

		    					});
		    				}
		    			}
		    		});
				});

			  	var videosDetailsSlick =  $('#videos-details .videos-details-slider').slick({
			            dots: false,
			            autoplay: false,
			            speed: 300,
			            slidesToShow: 1,
			            slidesToScroll: 1,
			            infinite: false,
			            centerPadding: 0,
			            customPaging: 0,
			            nextArrow: '#videos-details-next',
			            prevArrow: '#videos-details-prev',
			            responsive: [
			              {
			                breakpoint: 991,
			                settings: {
			                  slidesToShow: 1,
			                  slidesToScroll: 1,
			                  infinite: true
			                }
			              },
			              {
			                breakpoint: 767,
			                settings: {
			                  slidesToShow: 1,
			                  slidesToScroll: 1,
			                  infinite: true
			                }
			              }
			            ]
			    });

			  	$('#frontpage-videos-list').on('click','a.view-videos-details',function(e){
			  		e.preventDefault();

			  		$('#videos-details').addClass('active');
			  		var slickSlideVideos = $(this).data('slide');

			  		if(slickSlideVideos && Number.isInteger(slickSlideVideos))
			  			videosDetailsSlick.slick('slickGoTo', slickSlideVideos);
			  		else
			  			videosDetailsSlick.slick('slickGoTo', 0);


			  		return false;
			  	});

			  	$('#frontpage-videos-list').on('click','a.close-videos-details',function(e){
			  		e.preventDefault();

			  		$('#videos-details').removeClass('active');

			  		return false;
			  	});

		    }


	    }









		

		// if($('#frontpage-daniel').length){
		// 	$('#frontpage-daniel').on('click','a#daniel-scroll',function(e){
	  //       	e.preventDefault();
	  //       	if($('#frontpage-videos-list').length){
	  //       		if($('#frontpagevideo1').length){
	  //       			$('html, body').animate({
		// 		              scrollTop: $("#frontpage-videos-list").offset().top
		// 		         }, 400);
		// 						 window.playerVideo1.play();
		// 					}
	  //       	}
	  //       	return false;
	  //       });
		// }

		if($('.sidebar-menu-mobile').length){
			$('.sidebar-menu-mobile').on('click','#courses-menu-sidebar',function(e){
	        	e.preventDefault();
	        	if($(this).hasClass('opened')){
	        		$(this).removeClass('opened');
	        		$('.sidebar-boxes.course-boxes').slideUp(600);
	        	}else{
	        		$(this).addClass('opened');
	        		$('.sidebar-boxes.course-boxes').slideDown(600);
	        	}

	        	return false;
	        });

	        $('.sidebar-menu-mobile').on('click','#articles-menu-sidebar',function(e){
	        	e.preventDefault();
	        	if($(this).hasClass('opened')){
	        		$(this).removeClass('opened');
	        		$('.sidebar-boxes.article-boxes').slideUp(600);
	        	}else{
	        		$(this).addClass('opened');
	        		$('.sidebar-boxes.article-boxes').slideDown(600);
	        	}

	        	return false;
	        });
		}


	    if($('#mobile-full-menu').length){
			$('.header-nav-bar').on('click','#mobile-full-menu',function(e){
	        	e.preventDefault();

	        	if($(this).hasClass('full-menu-opened')){
	        		$(this).removeClass('full-menu-opened');
	        		$('#main-menu-web').addClass('d-none')
	        	}else{
	        		$(this).addClass('full-menu-opened');
	        		$('#main-menu-web').removeClass('d-none')
	        	}

	        	return false;
	        });

			if($('#mobile-full-close').length){
				$('.header-nav-bar').on('click','#mobile-full-close',function(e){
		        	e.preventDefault();

		        	if($('#mobile-full-menu').hasClass('full-menu-opened')){
		        		$('#mobile-full-menu').removeClass('full-menu-opened');
		        		$('#main-menu-web').addClass('d-none')
		        	}else{
		        		$('#mobile-full-menu').addClass('full-menu-opened');
		        		$('#main-menu-web').removeClass('d-none')
		        	}

		        	return false;
		        });
			}

		}

		if($('#testimonials-view').length > 0){

			$('#testimonials-view').on('click','a#load-more-testimonials',function(e){
	        	e.preventDefault();
	        	var hiddenRows = $('#testimonials-view').find('.row.d-none');
	        	$(this).closest('.row').addClass('d-none');
	        	$.each(hiddenRows,function(i,k){
	        		$(k).removeClass('d-none');
	        		var videoFrame = $(k).find('.video-frame');
	        		$.each(videoFrame,function(l,m){
		    			if($(m).data('url')){
		    				var videoLink = $(m).data('url');
		    				if(videoLink){
		    					var iframeHtml = '<iframe src="'+videoLink+'" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true" loading="lazy" class="lazyload"></iframe>';
		    					$(m).html(iframeHtml).promise().done(function(){

		    					});
		    				}
		    			}
		    		});
	        	});

	        	return false;
	        });

		    if($('#testimonials-view .video-details-video').length > 0){
	    		var videoIframes = $('#testimonials-view .video-details-video').find('.video-frame');
	    		$.each(videoIframes,function(i,k){
	    			if($(k).data('url')){
	    				var videoLink = $(k).data('url');
	    				if(videoLink){
	    					if($(k).closest('.row').hasClass('d-none')){

	    					}else{
		    					var iframeHtml = '<iframe src="'+videoLink+'" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true" loading="lazy" class="lazyload"></iframe>';
		    					$(k).html(iframeHtml).promise().done(function(){

		    					});
	    					}
	    				}
	    			}
	    		});
			}
		}


		if($('.sidebar-col').length > 0 && $('.sidebar-box').length > 0){
	        // var activeCourse = $('.sidebar-col').find('.sidebar-box.active');
	        // var activeLink = false;

	        // if($(activeCourse).length){
	        // 	var activeChapter = $(activeCourse).find('.accordion-button:not(.collapsed)');
	        // 	if($(activeChapter).length)
	        // 		activeLink = $(activeChapter).attr('href');
	        // 	else
	        // 		activeLink = $(activeCourse).find('.course-videos-link').attr('href');

	        // }else
	        // 	activeLink = '/courses';



	        // if(activeLink){
	        //     setTimeout(function() {
	        //         get_videos_view(activeLink);
	        //     }, 1600);
	        // }

	    //     $('.sidebar-box').on('click','a.course-videos-link',function(e){
	    //     	e.preventDefault();
	    //     	var activeLink = $(this).attr('href');
	    //     	var thisbox = $(this).closest('.sidebar-box');
	    //     	if(activeLink){
		   //          get_videos_view(activeLink);
		   //      }

		   //      if($(thisbox).hasClass('active')){

		   //      }else{
		   //      	$('.sidebar-col').find('.sidebar-box.active').removeClass('active');
		   //      	var dataTitle = $(this).data('title');
		   //      	var coursesTitle = '<a class="remove-filter" href="javascript:;" ></a><h1>'+dataTitle+'</h1>';
		   //      	$('.courses-title-hld .courses-title').html(coursesTitle);
		   //      	$(thisbox).addClass('active');

		   //      	var notActiveSidebar = $('.sidebar-col').find('.sidebar-box:not(.active)');
					// $.each(notActiveSidebar,function(i,k){
					// 	var accordion = $(k).find('.accordion-collapse.show');
					// 	if($(accordion).length){
					// 		$(accordion).collapse('hide');
					// 	}
					// });
		   //      }

	    //     	return false;
	    //     });


	   //      $('.sidebar-box').on('click','a.accordion-button',function(e){
	   //      	var activeLink = $(this).attr('href');
	   //      	var thisbox = $(this).closest('.sidebar-box');

	   //      	if(activeLink){
		  //           get_videos_view(activeLink);
		  //       }

		  //       if($(thisbox).hasClass('active')){

		  //       }else{
		  //       	$('.sidebar-col').find('.sidebar-box.active').removeClass('active');

		  //       	var dataTitle = $(thisbox).find('a.course-videos-link').data('title');
		  //       	var coursesTitle = '<a class="remove-filter" href="javascript:;" ></a><h1>'+dataTitle+'</h1>';
		  //       	$('.courses-title-hld .courses-title').html(coursesTitle);
				// 	$(thisbox).addClass('active');

				// 	var notActiveSidebar = $('.sidebar-col').find('.sidebar-box:not(.active)');
				// 	$.each(notActiveSidebar,function(i,k){
				// 		var accordion = $(k).find('.accordion-collapse.show');
				// 		if($(accordion).length){
				// 			$(accordion).collapse('hide');
				// 		}
				// 	});
		  //       }

	   //      });


	   //      $('#course-view').on('click','.courses-title-hld a.remove-filter',function(e){
	   //      	e.preventDefault();
	   //      	$('.sidebar-col').find('.sidebar-box.active').removeClass('active');
	   //      	$('.courses-title-hld .courses-title').html(' ');
	   //      	get_videos_view('/courses');


	   //      	var notActiveSidebar = $('.sidebar-col').find('.sidebar-box:not(.active)');
				// $.each(notActiveSidebar,function(i,k){
				// 	var accordion = $(k).find('.accordion-collapse.show');
				// 	if($(accordion).length){
				// 		$(accordion).collapse('hide');
				// 	}
				// });

	   //      	return false;
	   //      })

	        $('#course-view').on('click','.pagination a.page-link',function(e){
	        	e.preventDefault();
	        	var activeLink = $(this).attr('href');
	        	if(activeLink){
		            get_videos_view(activeLink);
		        }

	        	return false;
	        })


	    }



		if($('#video-links-resources').length > 0){
			$('#video-links-resources').on('click','.resources-filter button',function(e){
				e.preventDefault();
				if($(this).hasClass('active')){

				}else{
					$('#video-links-resources .resources-filter').find('.active').removeClass('active');
					var tag = $(this).data('type');
					var panels = $('#video-links-resources').find('.card');

					$.each(panels,function(i,k){
						if(tag != 'all'){
							if(tag == $(k).data('type'))
								$(k).fadeIn();
							else
								$(k).hide();

						}else{
							$(k).fadeIn();
						}
					});
					$(this).addClass('active');
				}
				return false
			})




		}


		if($('.courses-list-holder').length > 0){
		  	$('.courses-list-holder').slick({
		            dots: true,
		            autoplay: true,
		            autoplaySpeed: 6500,
		            infinite: true,
		            speed: 300,
		            slidesToShow: 3,
		            slidesToScroll: 3,
		            nextArrow: '#courses-list-next',
		            prevArrow: '#courses-list-prev',
		            responsive: [
		              {
		                breakpoint: 991,
		                settings: {
		                  slidesToShow: 2,
		                  slidesToScroll: 2,
		                  infinite: true
		                }
		              },
		              {
		                breakpoint: 767,
		                settings: {
											dots: false,
		                  slidesToShow: 1,
		                  slidesToScroll: 1,
		                  infinite: true
		                }
		              }
		            ]
		    });

		    if($('#course-details').length > 0){
		    	$('#course-details .course-details-slider').on('init', function(slick){
		    		var courseVideoIframes = $('#course-details .course-details-slider').find('.video-frame');
		    		$.each(courseVideoIframes,function(i,k){
		    			if($(k).data('url')){
		    				var courseVideoLink = $(k).data('url');
		    				if(courseVideoLink){
		    					var iframeHtml = '<iframe src="'+courseVideoLink+'" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true" loading="lazy" class="lazyload"></iframe>';
		    					$(k).html(iframeHtml).promise().done(function(){

		    					});
		    				}
		    			}
		    		});
				});

			  	var courseDetailsSlick =  $('#course-details .course-details-slider').slick({
			            dots: false,
			            autoplay: false,
			            speed: 300,
			            slidesToShow: 1,
			            slidesToScroll: 1,
			            infinite: true,
			            centerPadding: 0,
			            customPaging: 0,
			            nextArrow: '#courses-details-next',
			            prevArrow: '#courses-details-prev',
			            responsive: [
			              {
			                breakpoint: 991,
			                settings: {
			                  slidesToShow: 1,
			                  slidesToScroll: 1,
			                  infinite: true
			                }
			              },
			              {
			                breakpoint: 767,
			                settings: {
			                  slidesToShow: 1,
			                  slidesToScroll: 1,
			                  infinite: true
			                }
			              }
			            ]
			    });

			  	$('#courses-list').on('click','a.view-course-details',function(e){
			  		e.preventDefault();

			  		$('#course-details').addClass('active');
			  		var slickSlide = $(this).data('slide');

			  		if(slickSlide && Number.isInteger(slickSlide))
			  			courseDetailsSlick.slick('slickGoTo', slickSlide);
			  		else
			  			courseDetailsSlick.slick('slickGoTo', 0);


			  		return false;
			  	});

			  	$('#courses-list').on('click','a.close-course-details',function(e){
			  		e.preventDefault();

			  		$('#course-details').removeClass('active');

			  		return false;
			  	});

		    }


	    }

	    if($('#posts-list-caro').length > 0){
		  	$('#posts-list-caro .posts-list-holder').slick({
		            dots: true,
		            autoplay: true,
		            autoplaySpeed: 6500,
		            infinite: true,
		            speed: 300,
		            slidesToShow: 3,
		            slidesToScroll: 3,
		            nextArrow: '#posts-list-next',
		            prevArrow: '#posts-list-prev',
		            responsive: [
		              {
		                breakpoint: 991,
		                settings: {
		                  slidesToShow: 2,
		                  slidesToScroll: 2,
		                  infinite: true
		                }
		              },
		              {
		                breakpoint: 767,
		                settings: {
		                  slidesToShow: 1,
		                  slidesToScroll: 1,
		                  infinite: true
		                }
		              }
		            ]
		    });

	    }



	    if($('#frontpage-testimonials-list').length && $('#frontpage-testimonials-list .testimonials-list-holder').length){
	  		$('#frontpage-testimonials-list .testimonials-list-holder').slick({
	            dots: false,
	            autoplay: true,
	            autoplaySpeed: 7500,
	            infinite: true,
	            speed: 400,
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            nextArrow: '#testimonial-list-next',
	            prevArrow: '#testimonial-list-prev',
	            responsive: [
	              {
	                breakpoint: 576,
	                settings: {
	                  slidesToShow: 1,
	                  slidesToScroll: 1,
	                  infinite: true
	                }
	              }
	            ]
	      });
	    }

	    if($('.products-rigth').length && $('.offer-items').length  && $(window).width() < 991){
	  		$('.offer-items').slick({
	            dots: false,
	            autoplay: true,
	            autoplaySpeed: 6500,
	            infinite: false,
	            speed: 300,
	            slidesToShow: 2,
	            slidesToScroll: 2,
	            nextArrow: '#product-list-next',
	            prevArrow: '#product-list-prev',
	            responsive: [
	              {
	                breakpoint: 576,
	                settings: {
	                  slidesToShow: 1,
	                  slidesToScroll: 1,
	                  infinite: true
	                }
	              }
	            ]
	      });
	    }








	});
})(jQuery);



$.showOverlay = function($el = 'body'){
    $($el).addClass('showed');
}

$.removeOverlay = function($el = 'body'){
    $($el).removeClass('showed');
}


$.fn.validate = function(rules,callback){
	var FUNCTIONS = {
	    bigger : function(object,rule){
	        if ($(object).val() > rule)
	          return true;
	        return false;
	    },
	    longer : function(object,rule){
	        var value = $(object).val();
	        if (value.length > rule)
	            return true;
	        return false;
	    },
	    shorter : function(object,rule){
	          var value = $(object).val();
	          if (value.length < rule)
	              return true;
	          return false;
	    },
	    eq : function(object,rule){

	          var value = $(object).val();
	          if (value.length < rule)
	              return true;
	          return false;
	    },
	    validEmail : function(object,rule){
	          var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	          return re.test($(object).val());
	    },
	    lesser : function(object,rule){
	      if ($(object).val() < rule) return true;
	      return false;
	    },
	    equal : function(object,rule){
	      if($(object).val() == rule) return true;
	      return false;
	    },
	    confirm : function(object,rule){
	      if($(object).val() == $('#'+rule).val() ) return true;

	      return false;
	    },
	    isChecked : function(object,rule){
	      if($(object).prop('checked')){
	        $(object).val('1');
	      }
	      else{
	        $(object).val('');
	      }
	    },
	    hasValue : function(object,rule){
	      var value = $(object).val();

	      if(!value || value=='' || value==' ' || value == null || typeof value == 'undefined')
	        return false;
	      else
	        return true;
	    },
	    isInt : function(object,rule){
	      var value = $(object).val();
	      if (rule=='1'){
	        return parseInt(value) % 1 == 0;
	      }
	      else
	        return !(parseInt(value) % 1 == 0);
	    }
	};

	var data_rules = $(this).attr('data-rules') || '',
	    rules = rules || {};

	var rules_split = data_rules.split(';');

    $.each(rules_split,function(i,rule){
        var matches = rule.split(':');
        if (matches[0] && matches[1])
          rules[matches[0]] = matches[1];
    });

	if (!$.isEmptyObject(rules)){

	    var rArray=[true],
	        that = this;

	    $.each(rules,function(aRule,aValue){
	        var callee = FUNCTIONS[aRule];
	        if (callee)
	            rArray.push(callee(that,aValue));
	    });

	    return ($.inArray(false, rArray)==-1);
	}
	else{
	  return true;
	}

}


$.fn.executeValidation = function(){
	var validArray = [],
	  message = '',
	  o = {},
	  valid = true;



	$(this).each(function(i,k){

	  var validation = $(k).validate(),
	      data_validation_message = $(k).attr('data-validation-message');

	  if (!validation){
	      $(k).addClass('not-valid');
	      message += '<p>'+data_validation_message+'</p>';
	  }
	  else{
	      $(k).removeClass('not-valid');
	  }

	  var name = $(k).attr('name'),
	      val = $(k).val();

	   if (o[name]) {
	       if (!o[name].push) {
	           o[name] = [o[name]];
	       }
	       o[name].push(val || '');
	   } else {
	       o[name] = val || '';
	   }

	  validArray.push({
	      'dom' : k,
	      'valid' : validation,
	      'message' : data_validation_message
	  });

	});

	$.each(validArray,function(i,k){
	  if (!k.valid){
	      valid = false;
	      return false;
	  }
	});
	return {'status':valid,'message':message,'validArray':validArray,'o':o};
}


function get_frontpage_videos($el = '#frontpage-videos-list'){
    $.ajax({
    	  async: true,
          method: "post",
          url: '/frontpage-videos',
          success: function(html) {
                if(html){
                   $($el).html(html);

                   $($el).html(html).promise().done(function(){


                   		if($($el).find('.videos-list-holder').length > 0){
						  	$($el).find('.videos-list-holder').slick({
						            dots: false,
						            autoplay: true,
						            autoplaySpeed: 6500,
						            infinite: false,
						            speed: 300,
						            slidesToShow: 3,
						            slidesToScroll: 3,
						            nextArrow: '#videos-list-next',
						            prevArrow: '#videos-list-prev',
						            responsive: [
						              {
						                breakpoint: 991,
						                settings: {
						                  slidesToShow: 3,
						                  slidesToScroll: 3,
						                  infinite: true
						                }
						              },
						              {
						                breakpoint: 767,
						                settings: {
						                  slidesToShow: 1,
						                  slidesToScroll: 1,
						                  infinite: true
						                }
						              }
						            ]
						    });

						  //   if($('#videos-details').length > 0){
						  //   	$('#videos-details .videos-details-slider').on('init', function(slick){
						  //   		var videoIframes = $('#videos-details .videos-details-slider').find('.video-frame');
						  //   		$.each(videoIframes,function(i,k){
						  //   			if($(k).data('url')){
						  //   				var videoLink = $(k).data('url');
						  //   				if(videoLink){
						  //   					var iframeHtml = '<iframe src="'+videoLink+'" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true" loading="lazy" class="lazyload"></iframe>';
						  //   					$(k).html(iframeHtml).promise().done(function(){

						  //   					});
						  //   				}
						  //   			}
						  //   		});
								// });

							 //  	var videosDetailsSlick =  $('#videos-details .videos-details-slider').slick({
							 //            dots: false,
							 //            autoplay: false,
							 //            speed: 300,
							 //            slidesToShow: 1,
							 //            slidesToScroll: 1,
							 //            infinite: false,
							 //            centerPadding: 0,
							 //            customPaging: 0,
							 //            nextArrow: '#videos-details-next',
							 //            prevArrow: '#videos-details-prev',
							 //            responsive: [
							 //              {
							 //                breakpoint: 991,
							 //                settings: {
							 //                  slidesToShow: 1,
							 //                  slidesToScroll: 1,
							 //                  infinite: true
							 //                }
							 //              },
							 //              {
							 //                breakpoint: 767,
							 //                settings: {
							 //                  slidesToShow: 1,
							 //                  slidesToScroll: 1,
							 //                  infinite: true
							 //                }
							 //              }
							 //            ]
							 //    });

							 //  	$('#frontpage-videos-list').on('click','a.view-videos-details',function(e){
							 //  		e.preventDefault();

							 //  		$('#videos-details').addClass('active');
							 //  		var slickSlideVideos = $(this).data('slide');

							 //  		if(slickSlideVideos && Number.isInteger(slickSlideVideos))
							 //  			videosDetailsSlick.slick('slickGoTo', slickSlideVideos);
							 //  		else
							 //  			videosDetailsSlick.slick('slickGoTo', 0);


							 //  		return false;
							 //  	});

							 //  	$('#frontpage-videos-list').on('click','a.close-videos-details',function(e){
							 //  		e.preventDefault();

							 //  		$('#videos-details').removeClass('active');

							 //  		return false;
							 //  	});

						  //   }
					    }

                        // window.addEventListener('DOMContentLoaded', function() {

							const frontpagevideo1 = window.Stream(document.getElementById('frontpagevideo1'));
							const frontpagevideo2 = window.Stream(document.getElementById('frontpagevideo2'));
							const frontpagevideo3 = window.Stream(document.getElementById('frontpagevideo3'));
							// var frontpagevideo1 = window.Stream($($el).find('#frontpagevideo1')[0]);
							// var frontpagevideo2 = window.Stream($($el).find('#frontpagevideo2')[0]);
							// var frontpagevideo3 = window.Stream($($el).find('#frontpagevideo3')[0]);
						  frontpagevideo1.addEventListener('play', () => {
						  	
								gtag('event', "Play", {
								  'event_category': "Home Videos",
								  'event_label': "Video 1",
								});
								$('.videos-list-holder').slick('slickSetOption', 'autoplay', false).slick('slickPause');
								frontpagevideo2.pause();
								frontpagevideo3.pause();
						  })

							frontpagevideo2.addEventListener('play', () => {
								
								gtag('event', "Play", {
								  'event_category': "Home Videos",
								  'event_label': "Video 2",
								});
								$('.videos-list-holder').slick('slickSetOption', 'autoplay', false).slick('slickPause');
								frontpagevideo1.pause();
								frontpagevideo3.pause();
						  })

							frontpagevideo3.addEventListener('play', () => {
								
								gtag('event', "Play", {
								  'event_category': "Home Videos",
								  'event_label': "Video 3",
								});
							  	$('.videos-list-holder').slick('slickSetOption', 'autoplay', false).slick('slickPause');
								frontpagevideo1.pause();
								frontpagevideo2.pause();
						  })
						});
                    // }); 

                   	

                }
          },
          error: function(html) {
            // $.removeOverlay($($el));
            // window.location.reload(true);
        }
    });
}

function get_videos_view(url = '', $el = '#videos-on-course', holder = 'video-content',scrollTo = false){
    // $.showOverlay($($el));
    $($el).find('.video-content').attr('id','').empty();
    $.ajax({
          method: "post",
          url: url,
          success: function(html) {
                if(html){
                   $($el).find('.video-content').attr('id',holder).html(html);
                }
                // $.removeOverlay($($el));

                // if(scrollTo){
                //     setTimeout(function () {
                //         var scrollTopNumber = $el.scrollTop() - 40;

                //         setTimeout(function () {
                //             $(parentDiv).animate({
                //                 scrollTop: parseInt(scrollTopNumber)
                //             }, 800);
                //         }, 50);

                //      }, 50);
                // }

          },
          error: function(html) {
            // $.removeOverlay($($el));
            // window.location.reload(true);
        }
    });
}
